import React from "react";

class Projects extends React.Component {
  render() {
    return (
      <div>
        {" "}
        <section className="section section--projects" id="projects">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 10"
            preserveAspectRatio="none"
          >
            <polygon points="100 10 0 0 0 10" />
          </svg>
          <div className="section__inner">
            <div className="block">
              <a className="pre" href="#projects">
                <span>#3</span>projects
              </a>
              <br />
              <p className="lead">
                Here are some of my projects, I had the pleasure working with:{" "}
                <br />
                <br />
                <p>
                  1. Intuition:{" "}
                  <a href="https://github.com/H4ckOm/Intuition">
                    <b>[Github]</b>
                  </a>
                </p>
                <br />
                <p>
                  2. e-LDA Web Portal:{" "}
                  <a href="http://eldalab.in/">
                    <b>[Link]</b>
                  </a>
                </p>
                <br />
                <p>
                  2. IIIT Kota Website:{" "}
                  <a href="https://iiitkota.ac.in">
                    <b>[Link]</b>
                  </a>
                </p>
                <br />
                <p>
                  3. Nari:{" "}
                  <a href="https://github.com/H4ckOm/Nari">
                    <b>[Github]</b>
                  </a>
                </p>
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Projects;
