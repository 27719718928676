import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import SideNav from "./components/SideNav";
import Hello from "./components/Hello";
import About from "./components/About";
import Background from "./components/Background";
import Projects from "./components/Projects";
import CoffeeWithMe from "./components/Cofee";

class Main extends React.Component {
  render() {
    return (
      <div>
        <SideNav />
        <Hello />
        <About />
        <Background />
        <Projects />
        <CoffeeWithMe />
      </div>
    );
  }
}

ReactDOM.render(<Main />, document.getElementById("root"));
