import React from "react";

class CoffeeWithMe extends React.Component {
  render() {
    return (
      <div>
        <section className="section section--coffewithme" id="coffewithme">
          <div className="section__inner">
            <div className="block">
              <a className="pre" href="#coffewithme">
                <span>#5</span>Cofee With Me
              </a>
              <p className="lead">         
 If you have a query or wanna talk, I love tech conversations. You can reach me out here for a coffee.
                <br />
                <br />
                <span>
                  <a href="mailto:omkarvd7066@gmail.com">
                    omkarvd7066@gmail.com
                  </a>
                  <br />
                  <br />
                </span>
              </p>
              <p className="lead">
                More links:{" "}
                <a href="https://www.facebook.com/h4ckom/">Facebook</a>,{" "}
                <a href="https://www.linkedin.com/in/omkarvd/">Linkedin</a> and{" "}
                <a href="https://twitter.com/H4ckOm/">Twitter</a>.
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CoffeeWithMe;
