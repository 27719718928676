import React from "react";

class SideNav extends React.Component {
  render() {
    return (
      <div>
        <nav className="side">
          <div className="side__inner">
            <div className="top">
              <a href="/#">Omkar Deshmukh</a>
            </div>
            <div className="bottom">
              <a href="https://twitter.com/H4ckOm/">
                <i class="icon fab fa-twitter"></i>
              </a>
              <a href="https://www.facebook.com/h4ckom/">
                <i class="icon fab fa-facebook-square"></i>
              </a>
              <a href="https://www.instagram.com/its_omkar_d/">
                <i class="icon fab fa-instagram-square"></i>
              </a>
              <a href="https://www.linkedin.com/in/omkarvd/">
                <i class="icon fab fa-linkedin"></i>
              </a>
              <a href="https://github.com/h4ckOm/">
                <i class="icon fab fa-github"></i>
              </a>
              <a href="mailto:omkarvd7066@gmail.com">
                <i class="icon fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default SideNav;
