import React from "react";

class Background extends React.Component {
  render() {
    return (
      <div>
        <section className="section section--background" id="achievements">
          <div className="section__inner">
            <div className="block">
              <a className="pre" href="#about">
                <span>#2</span>Achievements
              </a>
              <p className="lead">
                I do competitive
                programming and enjoying it for one year. I love to solve
                Puzzles, Algorithmic problems, and Implementing Data Structures
                as my hobby.
                <br />
                <br />
                Something where I'm active on:
                <br />
                <a href="https://codeforces.com/profile/Omkar">
                  Codeforces
                </a>{" "}
                <a href="https://www.codechef.com/users/domnic">Codechef</a>{" "}
                <br />
                <br /> Here are some of my programming achievements:
                <br />
                <br />

              </p>
              <h6 style = {{paddingLeft: 20}}>
                <ul>
    <li>Ranked 66th Globally among 7K participants in Codechef May Lunchtime 2021 Div 2</li>
    <li>Ranked 52nd Globally among 6K participants in Codechef Feb Long Challenge 2021 Div 2</li>
    <li>Ranked 75th Globally among 13K participants in LeetCode Weekly Contest 246</li>
    <li>Winner at Code-a-Thon contest organized in annual tech fest of Dept. of CS, Delhi University</li>
    <li>Ranked 476th Globally among 26K participants in Codeforces Round 728</li>
    <li>Ranked 23rd in India (42nd Globally) among 9K participants in HackerEarth&rsquo;s April Circuit 2020</li>
    <li>Ranked 566th Globally among 10K participants in Google Kickstart Round D 2021</li>
    <li>Ranked 520th Globally among 6K participants in Google Kickstart Round H 2020 </li>
    </ul>
              </h6>
            </div>
          </div>
          
        </section>
      </div>
    );
  }
}

export default Background;
