import React from "react";

class About extends React.Component {
  render() {
    return (
      <div>
        <section className="section section--about" id="background">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 10"
            preserveAspectRatio="none"
          >
            <polygon points="100 0 100 10 0 10" />
          </svg>
          <div className="section__inner">
            <div className="block">
              <a className="pre" href="#background">
                <span>#1</span>background
              </a>
              <p className="lead">
              I'm currently pursuing my bachelors in computer science and technology from the Indian Institute of Information Technology Kota (IIIT Kota).
              </p>
              <br />
              <p className="lead">
              I'm having a current CGPA of 8.63/10 and the latest SGPA of 9.15/10. my expected graduation is in June 2023.
              </p>
              <br />
              <p className="lead">
                Some of my key courses are:
                <br/>
              </p>
                <h6 style = {{paddingLeft: 20}}>
                <ul>
                  <li>Data Structures and Algorithms</li>
                  <li>Design and Analysis of Algorithms</li>
                  <li>Object-Oriented Programming in C++</li>
                  <li>Database Management Systems</li>
                  <li>Operating Systems</li>
                  <li>Discrete Mathematics</li>
                </ul>
              </h6>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default About;
