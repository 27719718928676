import React from "react";

class Hello extends React.Component {
  render() {
    return (
      <div>
        <section className="section section--hello" id="hello">
          <div className="section__inner">
            <div className="block">
              {/* <a class="pre" href="#about"><span>#0</span></a> */}
              <h2>Hola Visitor</h2>
              <p>
                My name is Omkar. I am currently a junior studying Computer Science & Engineering and am passionate about using technology to solve real-life problems. My best attributes are problem-solving and quick comprehension of concepts.
              </p>
              <br />
              <ul className="anchornav">
                {/*<li>
                  <a href="#about">about </a>
                </li>*/}
                <li>
                  <a href="#background">background </a>
                </li>
                <li>
                  <a href="#achievements">Achievements </a>
                </li>
                <li>
                  <a href="#projects">projects</a>
                </li>
                {/*<li>
                  <a href="#experience">experience</a>
                </li>*/}
                <li>
                  <a href="#coffewithme">coffewithme </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Hello;
